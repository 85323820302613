import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "../../../components/fields/InputField";
import Default from "../../../layouts/auth/defaultLayout/index";
import {forgotPasswordAuth} from "src/api/authClientApi";
import {ForgotPasswordType, AuthForgotPasswordType} from "src/api/types";

function ForgotPasswordDefault() {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleForgotPassword = async(event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        if (email === '') {
            setError("Correo no válido.");
            setLoading(false);
            return;
        }
        setError("");

        const forgotPassword: ForgotPasswordType = { email };

        const response:AuthForgotPasswordType = await forgotPasswordAuth(forgotPassword);
        console.log("Respuesta de autenticación:", response.success);
        if (response.success) {
            console.log("Exito");
            navigate("/sign-in");
        }
        else {
            console.log(error);
            setError(response.message || "Correo no válido.");
        }
        setLoading(false);
    };

    return (
        <Default
            maincard={
                <div className="mb-16 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
                    {/* Sign in section */}
                    <div className="mt-[16vh] flex flex-col rounded-[20px] pb-16 pt-12 md:pr-6 lg:max-w-[90%] lg:pr-0 xl:max-w-[42%] xl:pr-10">
                        <h2 className="mb-2 text-4xl font-bold text-navy-600 dark:text-white">
                            ¿Olvidaste tu contraseña?
                        </h2>
                        <p className="mb-8 ml-1 mt-[10px] text-base text-gray-600">
                            No hay problema. Simplemente escribe tu
                            dirección de correo electrónico y te
                            enviaremos un enlace para restablecer
                            tu contraseña y elegir una nueva.
                        </p>
                        <form onSubmit={handleForgotPassword}>
                            <InputField
                                variant="auth"
                                label="Email*"
                                placeholder="mail@simmmple.com"
                                id="email"
                                type="text"
                                value={email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                            />
                            {/* button */}
                            <button
                                type="submit"
                                className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                            >
                                {loading ? "Enviando..." : "Enviar"}
                            </button>
                        </form>
                        {/* Email */}

                        {error && (
                            <p className="mt-4 text-lg text-brand-500 dark:text-white">
                                {error}
                            </p>
                        )}
                    </div>
                </div>
            }
        />
    );
}

export default ForgotPasswordDefault;
