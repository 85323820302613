import Card from "../card";
import { InformationCardType } from "../../types/keter";

interface GeneralInformationCardProps {
    cardHeader: string;
    cardText: string;
    informationCards: InformationCardType[];
}

function GeneralInformationCard(widgetProps: GeneralInformationCardProps) {
    return (
        <Card extra={"w-full h-full p-3"}>
            {/* Header */}
            <div className="mb-8 mt-2 w-full">
                <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
                    {widgetProps.cardHeader}
                </h4>
                <p className="mt-2 px-2 text-base text-gray-600">
                    {widgetProps.cardText}
                </p>
            </div>
            {/* Cards */}
            {widgetProps.informationCards.map((card, index) => {
                return (
                    <div
                        key={card.title}
                        className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none"
                    >
                        <p className="text-sm text-gray-600">{card.title}</p>
                        <p className="text-base font-medium text-navy-700 dark:text-white">
                            {card.content}
                        </p>
                    </div>
                );
            })}
            {widgetProps.informationCards.length === 0 && (
                <div className="flex h-full w-full items-center justify-center">
                    <p className="text-base text-gray-600">
                        No information available
                    </p>
                </div>
            )}
        </Card>
    );
}

export default GeneralInformationCard;
