import React from "react";

interface SaveBatteryDataProps {
    saveNewBatteryData: () => void;
    setCurrentStep: (step: number) => void;
    currentStep: number;
}
const SaveBatteryData: React.FC<SaveBatteryDataProps> = () => {
    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            {/* Header */}
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Battery Info
            </h4>

            {/* content */}

            <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2"></div>
        </div>
    );
}

export default SaveBatteryData;
