import Card from "../card";
import InputField from "../fields/InputField";
import TextField from "../fields/TextField";
import { ChangeEvent, useState } from "react";
import { TestSubjectProfileDataType } from "../../types/keter";

interface InformationProps {
    profileData: TestSubjectProfileDataType;
}

function EditProfileCard(widgetProps: InformationProps) {
    const [subjectProfileData, setSubjectProfileData] = useState(
        widgetProps.profileData
    );
    const saveSubjectProfileData = () => {
        console.log(`Save Subject Profile Data ${subjectProfileData}`);
    };
    const updateSubjectData = (e: ChangeEvent<HTMLInputElement>) => {
        if (
            e.target.name === "addressLine1" ||
            e.target.name === "addressLine2" ||
            e.target.name === "city" ||
            e.target.name === "state" ||
            e.target.name === "zip" ||
            e.target.name === "country"
        ) {
            setSubjectProfileData({
                ...subjectProfileData,
                address: {
                    ...subjectProfileData.address,
                    [e.target.name]: e.target.value,
                },
            });
        } else {
            setSubjectProfileData({
                ...subjectProfileData,
                [e.target.name]: e.target.value,
            });
        }
        const _dataProfile: string = JSON.stringify(subjectProfileData);
        console.log(`Update Subject Profile Data ${_dataProfile}`);
    };
    return (
        <Card extra={"w-full px-6 py-6 mb-3"}>
            {/* Header */}
            <div className="w-full px-[8px]">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Account Settings
                </h4>
                <p className="mt-1 text-base text-gray-600">
                    Here you can change the subjects account information
                </p>
            </div>
            {/* inputs */}
            <div className="mt-[37px] grid grid-cols-1 gap-3 md:grid-cols-2">
                <InputField
                    extra="mb-3"
                    name="firstName"
                    label="Frist Name"
                    placeholder="John"
                    id="firstname"
                    type="text"
                    value={subjectProfileData.firstName}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Last Name"
                    name="lastName"
                    placeholder="Doe"
                    id="lastname"
                    type="text"
                    value={subjectProfileData.lastName}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Phone Number"
                    name="phoneNumber"
                    placeholder="08012345678"
                    id="phone"
                    type="text"
                    value={subjectProfileData.phoneNumber}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Government ID"
                    name="governmentId"
                    placeholder="1234567890"
                    id="govId"
                    type="text"
                    value={subjectProfileData.governmentId}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Date of Birth"
                    name="birthDate"
                    placeholder="01/01/2000"
                    id="birthDate"
                    type="text"
                    value={subjectProfileData.birthDate}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Email Address"
                    placeholder="mail@simmmple.com"
                    id="email"
                    type="text"
                    value={subjectProfileData.email}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Job"
                    name="jobTitle"
                    placeholder="Web Developer"
                    id="job"
                    type="text"
                    value={subjectProfileData.jobTitle}
                    onChange={updateSubjectData}
                />

                <InputField
                    extra="mb-3"
                    label="Address Line 1"
                    name="addressLine1"
                    placeholder="123, Main Street"
                    id="address1"
                    type="text"
                    value={subjectProfileData.address.addressLine1}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Address Line 2"
                    name="addressLine2"
                    placeholder="Apartment, studio, or floor"
                    id="address2"
                    type="text"
                    value={subjectProfileData.address.addressLine2}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="City"
                    name="city"
                    placeholder="City"
                    id="city"
                    type="text"
                    value={subjectProfileData.address.city}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="State"
                    name="state"
                    placeholder="State"
                    id="state"
                    type="text"
                    value={subjectProfileData.address.state}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Zip"
                    name="zip"
                    placeholder="Zip"
                    id="zip"
                    type="text"
                    value={subjectProfileData.address.zip}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Country"
                    name="country"
                    placeholder="Country"
                    id="country"
                    type="text"
                    value={subjectProfileData.address.country}
                    onChange={updateSubjectData}
                />
            </div>
            {/* full width inputs */}
            <TextField
                extra="mb-4"
                label="About Me"
                name="aboutMe"
                placeholder="Tell something about yourself in 150 characters!"
                id="textarea"
                cols={30}
                rows={5}
                value={subjectProfileData.aboutMe}
                onChange={updateSubjectData}
            />
            <div className="flex w-full justify-end">
                <button
                    onClick={() => saveSubjectProfileData()}
                    className="linear rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                    Save Changes
                </button>
            </div>
        </Card>
    );
}

export default EditProfileCard;
