const SearchIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mx-3 my-[12px] h-5 w-5 cursor-pointer text-gray-400 dark:text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
        </svg>
    );
};

export default SearchIcon;
