import { Navigate, Route, Routes } from "react-router-dom";
import "./assets/css/Plugins.css";
import React, { useEffect, useState } from "react";
import Auth from "./layouts/auth";
import { SettingType, UserType } from "./types/keter";
import avatar4 from "./assets/img/avatars/avatar4.png";
import avatar3 from "./assets/img/avatars/avatar3.png";
import banner from "./assets/img/profile/banner.png";
import DashboardLayout from "./components/dashboardLayout";
import TestSubjectProfile from "./views/admin/subjects/profile";
import UsersOverview from "./views/admin/subjects/overview";
import AddTest from "./views/admin/tests/addTest";
import TestsOverview from "./views/admin/tests/testsOverview";
import NewSubject from "./views/admin/subjects/newSubject";
import OverviewUser from "./views/admin/users/overviewUser";
import NewUser from "./views/admin/users/newUser";
import AddTestBattery from "./views/admin/tests/addTestBatery";
import NotFound from "./views/admin/subjects/notfound";

function App() {
    let subjectsData;
    subjectsData = [
        {
            name: "Vlad Mihalache",
            testGroup: "Solo Cleaver",
            testType: "Cleaver",
            jobTitle: "Gerente",
            testStatus: "Administrator",
            creationDate: "Oct 24, 2022",
            startDate: "Oct 24, 2022r",
            endDate: "Oct 24, 2022",
            expirationDate: "Oct 24, 2022",
        },
    ];

    const [themeApp, setThemeApp] = useState<any>({
        "--background-100": "#FFFFFF",
        "--background-900": "#070f2e",
        "--shadow-100": "rgba(112, 144, 176, 0.08)",
        "--color-50": "#E9E3FF",
        "--color-100": "#C0B8FE",
        "--color-200": "#A195FD",
        "--color-300": "#8171FC",
        "--color-400": "#7551FF",
        "--color-500": "#422AFB",
        "--color-600": "#3311DB",
        "--color-700": "#2111A5",
        "--color-800": "#190793",
        "--color-900": "#11047A",
    });

    // When the theme state changes, this effect will update the CSS variables in the document's root element
    useEffect(() => {
        let color;
        for (color in themeApp) {
            document.documentElement.style.setProperty(color, themeApp[color]);
        }
        //eslint-disable-next-line
    }, [themeApp]);

    let sidebarData;
    sidebarData = {
        brandLogo:
            "https://minio.cloudjinx.com/web-sites-assets/cloudketer/images/logo_transparent.png",
        profilePic: avatar4,
        fullName: "Abby Littman",
        userType: "Admin",
        menuItems: [
            {
                name: "Subjects",
                icon: "HiOutlineUser",
                path: "/admin/subjects",
                items: [
                    {
                        name: "Overview",
                        icon: "HiOutlineUser",
                        path: "/admin/subjects/overview",
                        items: [],
                    },
                    {
                        name: "Profile",
                        icon: "HiOutlineUser",
                        path: "/admin/subjects/123456/profile",
                        items: [],
                    },
                    {
                        name: "Add Subject",
                        icon: "HiOutlineUser",
                        path: "/admin/subjects/add-subject",
                        items: [],
                    },
                ],
            },
            {
                name: "Users",
                icon: "HiOutlineUser",
                path: "/admin/users",
                items: [
                    {
                        name: "Overview",
                        icon: "HiOutlineUser",
                        path: "/admin/users/overview",
                        items: [],
                    },
                    {
                        name: "Profile",
                        icon: "HiOutlineUser",
                        path: "/admin/users/123456/profile",
                        items: [],
                    },
                    {
                        name: "Add User",
                        icon: "HiOutlineUser",
                        path: "/admin/users/add-user",
                        items: [],
                    },
                ],
            },
            {
                name: "Tests",
                icon: "HiOutlineUser",
                path: "/admin/tests",
                items: [
                    {
                        name: "Overview",
                        icon: "",
                        path: "/admin/tests/overview",
                        items: [],
                    },
                    {
                        name: "Add Test",
                        icon: "HiOutlineDocumentText",
                        path: "/admin/tests/add-test",
                        items: [],
                    },
                    {
                        name: "Add Test Battery",
                        icon: "",
                        path: "/admin/tests/add-test-battery",
                        items: [],
                    },
                ],
            },
        ],
    };
    let pageViewDataSubjectsProfile;
    pageViewDataSubjectsProfile = {
        profileData: {
            id: "1d6a9sd8f",
            governmentId: "123456789",
            firstName: "Vlad",
            lastName: "Mihalache",
            jobTitle: "Gerente",
            creationDate: "Oct 24, 2022",
            email: "testing@email.com",
            phoneNumber: "55555555",
            address: {
                addressLine1: "1234 Main St",
                addressLine2: "Apt 123",
                city: "New York",
                state: "NY",
                zip: "12345",
                country: "USA",
            },
            aboutMe:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
            birthDate: "Oct 24, 2022",
            profilePic: avatar3,
            userType: UserType.TEST_SUBJECT,
        },
        settingsCardData: {
            cardHeader: "Account Settings",
            settings: [
                {
                    settingId: "emailNotifications",
                    settingName: "Email Notifications",
                    settingLabel: "Enable Email Notifications",
                    settingDescription:
                        "Controls whether the user receives email notifications for account activity",
                    settingValue: false,
                    settingType: SettingType.NOTIFICATION,
                },
                {
                    settingId: "smsNotifications",
                    settingName: "SMS Notifications",
                    settingLabel: "Enable SMS Notifications",
                    settingDescription:
                        "Controls whether the user receives SMS notifications for account activity",
                    settingValue: true,
                    settingType: SettingType.NOTIFICATION,
                },
                {
                    settingId: "profileVisibility",
                    settingName: "Profile Visibility",
                    settingLabel: "Make Profile Public",
                    settingDescription:
                        "Controls whether the user's profile is visible to other users",
                    settingValue: false,
                    settingType: SettingType.PRIVACY,
                },
                {
                    settingId: "twoFactorAuth",
                    settingName: "Two-Factor Authentication",
                    settingLabel: "Enable Two-Factor Authentication",
                    settingDescription:
                        "Controls whether the user has two-factor authentication enabled",
                    settingValue: false,
                    settingType: SettingType.SECURITY,
                },
            ],
        },
        bannerPic: banner,
        generalInformationData: {
            cardHeader: "Professional Information",
            cardText: "Subjects professional information",
            informationCards: [
                {
                    title: "Languages",
                    content: "English, Spanish, French",
                },
                {
                    title: "Education",
                    content: "Bachelors in Computer Science",
                },
                {
                    title: "Experience",
                    content: "5 years in software development",
                },
                {
                    title: "Certifications",
                    content: "AWS Certified Developer",
                },
                {
                    title: "Skills",
                    content: "React, Node.js, AWS, Docker",
                },
            ],
        },
    };
    let pageViewDataAddTest;
    pageViewDataAddTest = {};
    // @ts-ignore
    return (
        <Routes>
            <Route
                path="/"
                element={<Navigate to="/admin/subjects/123456/profile" />}
            />
            <Route path="/sign-in" element={<Auth path="/sign-in" />} />
            <Route path="/sign-up" element={<Auth path="/sign-up" />} />
            <Route path="/verification" element={<Auth path="/verification" />} />
            <Route path="/forgot-password" element={<Auth path="/forgot-password" />} />
            <Route path="/lock" element={<Auth path="/lock" />} />

            <Route
                path="/admin/subjects/overview"
                element={
                    <DashboardLayout
                        theme={themeApp}
                        setTheme={setThemeApp}
                        sideBarData={sidebarData}
                        navBarData={""}
                    >
                        <UsersOverview subjectsData={subjectsData} />
                    </DashboardLayout>
                }
            />
            <Route
                path="/admin/subjects/123456/profile"
                element={
                    <DashboardLayout
                        theme={themeApp}
                        setTheme={setThemeApp}
                        sideBarData={sidebarData}
                        navBarData={""}
                    >
                        <TestSubjectProfile
                            profileData={
                                pageViewDataSubjectsProfile.profileData
                            }
                            generalInformationData={
                                pageViewDataSubjectsProfile.generalInformationData
                            }
                            bannerPic={pageViewDataSubjectsProfile.bannerPic}
                            settingsCardData={
                                pageViewDataSubjectsProfile.settingsCardData
                            }
                        />
                    </DashboardLayout>
                }
            />

            <Route
                path="/admin/tests/overview"
                element={
                    <DashboardLayout
                        theme={themeApp}
                        setTheme={setThemeApp}
                        sideBarData={sidebarData}
                        navBarData={""}
                    >
                        <TestsOverview />
                    </DashboardLayout>
                }
            />

            <Route
                path="/admin/tests/add-test"
                element={
                    <DashboardLayout
                        theme={themeApp}
                        setTheme={setThemeApp}
                        sideBarData={sidebarData}
                        navBarData={""}
                    >
                        <AddTest />
                    </DashboardLayout>
                }
            />

            <Route
                path="/admin/tests/add-test-battery"
                element={
                    <DashboardLayout
                        theme={themeApp}
                        setTheme={setThemeApp}
                        sideBarData={sidebarData}
                        navBarData={""}
                    >
                        <AddTestBattery />
                    </DashboardLayout>
                }
            />

            <Route
                path="/admin/subjects/add-subject"
                element={
                    <DashboardLayout
                        theme={themeApp}
                        setTheme={setThemeApp}
                        sideBarData={sidebarData}
                        navBarData={""}
                    >
                        <NewSubject />
                    </DashboardLayout>
                }
            />

            <Route
                path="/admin/users/add-user"
                element={
                    <DashboardLayout
                        theme={themeApp}
                        setTheme={setThemeApp}
                        sideBarData={sidebarData}
                        navBarData={""}
                    >
                        <NewUser />
                    </DashboardLayout>
                }
            />
            
            <Route
                path="/admin/users/overview"
                element={
                    <DashboardLayout
                        theme={themeApp}
                        setTheme={setThemeApp}
                        sideBarData={sidebarData}
                        navBarData={""}
                    >
                        <OverviewUser />
                    </DashboardLayout>
                }
            />

            <Route
                path="/admin/subjects/notfound"
                element={
                    <DashboardLayout
                        theme={themeApp}
                        setTheme={setThemeApp}
                        sideBarData={sidebarData}
                        navBarData={""}
                    >
                        <NotFound/>
                    </DashboardLayout>
                }
            />
        </Routes>
    );
}

export default App;
