import Links from "./components/Links";

import {
    renderThumb,
    renderTrack,
    renderView,
    renderViewMini,
} from "../scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import Card from "../card";
import React from "react";
import { SideBarDataType } from "../../types/keter";

interface SidebarProps {
    sideBarData: SideBarDataType;
    open: boolean;
    onClose: React.MouseEventHandler<HTMLSpanElement>;
    variant?: string;
    mini: boolean;
    hovered: boolean;
    setHovered: React.Dispatch<React.SetStateAction<boolean>>;
}

function Sidebar(widgetProps: SidebarProps) {
    const { open, onClose, variant, hovered, setHovered } = widgetProps;
    const { menuItems, fullName, userType, profilePic, brandLogo } =
        widgetProps.sideBarData;
    return (
        <Card
            extra={`w-full h-[96.5vh] sm:my-4 !rounded-[20px] rtl:mr-3 ltr:ml-3 `}
        >
            <Scrollbars
                autoHide
                renderTrackVertical={renderTrack}
                renderThumbVertical={renderThumb}
                renderView={
                    widgetProps.mini === false ? renderView : renderViewMini
                }
            >
                <div className="flex h-full w-full flex-col justify-between overflow-x-hidden">
                    <div className="m-0 w-full flex-grow flex-col items-center justify-center">
                        {/*Logo and Name*/}
                        <div
                            className={`flex w-full flex-shrink items-center ${
                                widgetProps.mini === false
                                    ? "mt-4 justify-start"
                                    : "mt-2 justify-center"
                            }`}
                        >
                            <div
                                className={`ml-[20px] font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white ${
                                    widgetProps.mini === false
                                        ? "block"
                                        : "hidden"
                                }`}
                            >
                                <img
                                    className="w-[80px]"
                                    src={brandLogo}
                                    alt="Logo"
                                />
                            </div>
                            <div
                                className={`flex w-full flex-col items-center justify-center font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white ${
                                    widgetProps.mini === false
                                        ? "hidden"
                                        : "block"
                                }`}
                            >
                                <img
                                    className="w-[40px]"
                                    src={brandLogo}
                                    alt="Logo"
                                />
                            </div>
                        </div>
                        {/*Divider*/}
                        <div className="mx-1 mb-7 mt-[15px] h-px w-full bg-gray-200 dark:bg-white/10" />
                        {/*Menu Items*/}
                        <div className="flex-grow">
                            <ul>
                                <Links
                                    mini={widgetProps.mini}
                                    hovered={hovered}
                                    menuItems={menuItems}
                                />
                            </ul>
                        </div>
                    </div>
                    {/*Menu Footer*/}
                    <div className="mb-[30px] mt-[28px]">
                        <div className="mt-5 flex items-center justify-center gap-3">
                            <div className="h-12 w-12 rounded-full bg-blue-200">
                                <img
                                    src={profilePic}
                                    className="rounded-full"
                                    alt="avatar"
                                />
                            </div>
                            <div
                                className={`ml-1 ${
                                    widgetProps.mini === false
                                        ? "block"
                                        : "block xl:hidden"
                                }`}
                            >
                                <h4 className="text-base font-bold text-navy-700 dark:text-white">
                                    {fullName}
                                </h4>
                                <p className="text-sm font-medium text-gray-600">
                                    {userType}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </Card>
    );
}

export default Sidebar;
