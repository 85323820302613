export enum UserType {
    ADMIN = "Admin",
    TEST_SUBJECT = "Test Subject",
    TESTER = "Tester",
    EMPLOYEE = "Employee",
}

export type NewUserData = {
    id: string;
    governmentId: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    lastName2?: string;
    jobTitle: string;
    creationDate: string;
    email: string;
    address: AddressType;
    birthDate: string;
    profilePic?: string;
    userType: UserType;
}

export enum TestCategory {
    PERSONALITY = "Personality",
    TECHNICAL = "Technical",
    PSYCHOMETRIC = "Psychometric",
    BEHAVIORAL = "Behavioral",
}

export type TestSubjectDataType = {
    name: string;
    testGroup: string;
    testType: string;
    jobTitle: string;
    testStatus: string;
    creationDate: string;
    startDate: string;
    endDate: string;
    expirationDate: string;
};

export type TestDataType = {
    id: string;
    name: string;
    description: string;
    evaluationProcess: string;
    testType: string;
    creationDate: string;
    testTime: string;
    category: TestCategory;
};

export type TestBatteryType = {
    id: string;
    name: string;
    description: string;
    tests: TestDataType[];
};

export type TestSubjectProfileDataType = {
    id: string;
    governmentId: string;
    curp?: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    lastName2?: string;
    jobTitle: string;
    creationDate: string;
    email: string;
    phoneNumber: string;
    address: AddressType;
    aboutMe: string;
    birthDate: string;
    profilePic?: string;
    userType: UserType;
};

export type SubjectProfilePageViewType = {
    profileData: TestSubjectProfileDataType;
    settingsCardData: SettingsToggleCardDataType;
    generalInformationData: GeneralInformationCardType;
    bannerPic: string;
};

export enum SettingType {
    NOTIFICATION = "Notification",
    PROFILE = "Profile",
    ACCOUNT = "Account",
    ACCESS = "Access",
    PRIVACY = "Privacy", // For settings related to data privacy and sharing
    SECURITY = "Security", // For settings related to account security like two-factor authentication
    PREFERENCES = "Preferences", // For user interface and other user preferences
    SYSTEM = "System", // For system-wide settings if applicable
}

export type SettingToggleType = {
    settingId: string;
    settingName: string;
    settingLabel: string;
    settingDescription: string;
    settingValue: boolean;
    settingIcon?: string;
    settingType: SettingType;
};

export type SettingsToggleCardDataType = {
    cardHeader: string;
    settings: SettingToggleType[];
};

export type GeneralInformationCardType = {
    cardHeader: string;
    cardText: string;
    informationCards: InformationCardType[];
};

export type AddressType = {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
};

export type SideBarDataType = {
    brandLogo: string;
    profilePic: string;
    fullName: string;
    userType: string;
    menuItems: MenuItem[];
};

export type MenuItem = {
    name: string;
    icon: string;
    path: string;
    items: MenuItem[];
};

enum ProjectState {
    ACTIVE = "Active",
    INACTIVE = "Inactive",
    ARCHIVED = "Archived",
    ON_HOLD = "On Hold",
    ON_TRACK = "On Track",
    DELAYED = "Delayed",
}

export type ProjectType = {
    id: string;
    name: string;
    description: string;
    summary: string;
    state: ProjectState;
    creationDate: string;
    startDate: string;
    endDate: string;
    image: string;
    link: string;
};

export type InformationCardType = {
    title: string;
    content: string;
};
