import InputField from "src/components/fields/InputField";
import { TestSubjectProfileDataType } from "../../../../../../types/keter";

import { ChangeEvent } from "react";

interface NewSubjectProps {
    updateSubjectData: (e: ChangeEvent<HTMLInputElement>) => void;
    subjectProfileData: TestSubjectProfileDataType;
}

function Address(widgetProps: NewSubjectProps) {
    let { subjectProfileData, updateSubjectData } = widgetProps;
    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            {/* Header */}
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Address
            </h4>
            {/* content */}
            <div className="mt-4">
                <div className="grid grid-cols-4 gap-3">
                    <InputField
                        extra="mb-3"
                        label="Country"
                        name="country"
                        placeholder="Country"
                        id="country"
                        type="text"
                        value={subjectProfileData.address.country}
                        onChange={updateSubjectData}
                    />
                    <InputField
                        extra="mb-3"
                        label="City"
                        name="city"
                        placeholder="City"
                        id="city"
                        type="text"
                        value={subjectProfileData.address.city}
                        onChange={updateSubjectData}
                    />
                    <InputField
                        extra="mb-3"
                        label="State"
                        name="state"
                        placeholder="State"
                        id="state"
                        type="text"
                        value={subjectProfileData.address.state}
                        onChange={updateSubjectData}
                    />
                    <InputField
                        extra="mb-3"
                        label="Zip"
                        name="zip"
                        placeholder="Zip"
                        id="zip"
                        type="text"
                        value={subjectProfileData.address.zip}
                        onChange={updateSubjectData}
                    />
                </div>
            </div>
        </div>
    );
}

export default Address;
