import React from "react";
import Card from "../../../../../components/card";
import SearchIcon from "../../../../../components/icons/SearchIcon";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

import {
    ColumnFiltersState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    useReactTable,
} from "@tanstack/react-table";
import { TestSubjectDataType } from "src/types/keter";

interface CheckTableProps {
    subjectsData: TestSubjectDataType[];
}

function CheckTable(widgetProps: CheckTableProps) {
    const { subjectsData } = widgetProps;
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);
    let defaultData = subjectsData;
    const [globalFilter, setGlobalFilter] = React.useState("");
    const createPages = (count: number) => {
        let arrPageCount = [];

        for (let i = 1; i <= count; i++) {
            arrPageCount.push(i);
        }

        return arrPageCount;
    };
    const columns = [
        columnHelper.accessor("name", {
            id: "name",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    NAME
                </p>
            ),
            cell: (info: any) => (
                <p className="font-medium text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("testGroup", {
            id: "testGroup",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    TEST GROUP
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("testType", {
            id: "testType",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    TEST TYPE
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("startDate", {
            id: "startDate",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    JOIN DATE
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("creationDate", {
            id: "creationDate",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    CREATION DATE
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("jobTitle", {
            id: "jobTitle",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    JOB TITLE
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("testStatus", {
            id: "testStatus",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    TEST STATUS
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("startDate", {
            id: "startDate",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    START DATE
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("endDate", {
            id: "endDate",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    END DATE
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("expirationDate", {
            id: "expirationDate",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    EXPIRATION
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
    ]; // eslint-disable-next-line
    const [data, setData] = React.useState(() => [...defaultData]);
    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: 10,
        });

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );
    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });

    return (
        <div className="w-full max-w-full">
            <Card extra={"w-full px-6 overflow-hidden"}>
                <div className="flex w-[400px] max-w-full items-center rounded-xl pt-[20px]">
                    <div className="flex h-[38px] w-[400px] flex-grow items-center rounded-xl bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white">
                        <SearchIcon />
                        <input
                            value={globalFilter ?? ""}
                            onChange={(e: any) =>
                                setGlobalFilter(e.target.value)
                            }
                            type="text"
                            placeholder="Search...."
                            className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
                        />
                    </div>
                </div>

                <div className="mt-8">
                    <div className="w-full  overflow-x-scroll">
                        <table className="w-full">
                            <thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr
                                        key={headerGroup.id}
                                        className="!border-px !border-gray-400"
                                    >
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                    className="cursor-pointer border-b border-gray-200 pb-2 pr-4 pt-4 text-start dark:border-white/30"
                                                >
                                                    <div className="items-center justify-between text-xs text-gray-200">
                                                        {flexRender(
                                                            header.column
                                                                .columnDef
                                                                .header,
                                                            header.getContext()
                                                        )}
                                                        {{
                                                            asc: "",
                                                            desc: "",
                                                        }[
                                                            header.column.getIsSorted() as string
                                                        ] ?? null}
                                                    </div>
                                                </th>
                                            );
                                        })}
                                        <th
                                            key="editColumn"
                                            colSpan={2}
                                            className="cursor-pointer border-b border-gray-200 pb-2 pr-4 pt-4 text-start dark:border-white/30"
                                        >
                                            <div className="items-center justify-between text-xs text-gray-200">
                                                <p className="text-sm font-bold text-gray-600 dark:text-white">
                                                    EDIT
                                                </p>
                                            </div>
                                        </th>
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table
                                    .getRowModel()
                                    .rows.slice(0, 7)
                                    .map((row) => {
                                        return (
                                            <tr key={row.id}>
                                                {row
                                                    .getVisibleCells()
                                                    .map((cell) => {
                                                        return (
                                                            <td
                                                                key={cell.id}
                                                                className="min-w-[150px] border-white/0 py-3  pr-4"
                                                            >
                                                                {flexRender(
                                                                    cell.column
                                                                        .columnDef
                                                                        .cell,
                                                                    cell.getContext()
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                    {/* pagination */}
                    <div className="mt-2 flex h-20 w-full items-center justify-between px-6">
                        {/* left side */}
                        <div className="flex items-center gap-3">
                            <p className="> Show rows per page text-sm text-gray-700">
                                Showing 10 rows per page
                            </p>
                        </div>
                        {/* right side */}
                        <div className="flex items-center gap-2">
                            <button
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                                className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                            >
                                <MdChevronLeft />
                            </button>

                            {createPages(table.getPageCount()).map(
                                (pageNumber, index) => {
                                    return (
                                        <button
                                            className={`linear flex h-10 w-10 items-center justify-center rounded-full p-2 text-sm transition duration-200 ${
                                                pageNumber === pageIndex + 1
                                                    ? "bg-brand-500 text-white hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                                    : "border-[1px] border-gray-400 bg-[transparent] dark:border-white dark:text-white"
                                            }`}
                                            onClick={() =>
                                                table.setPageIndex(
                                                    pageNumber - 1
                                                )
                                            }
                                            key={index}
                                        >
                                            {pageNumber}
                                        </button>
                                    );
                                }
                            )}
                            <button
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                                className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 `}
                            >
                                <MdChevronRight />
                            </button>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default CheckTable;
const columnHelper = createColumnHelper<TestSubjectDataType>();
