import React, { useState } from "react";
import Sidebar from "../sidebar";
import Navbar from "../navbar";
import { Portal } from "@chakra-ui/portal";
import Footer from "../footer/Footer";
import {
    renderThumb,
    renderTrack,
    renderView,
    renderViewMini,
} from "../scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import { SideBarDataType } from "../../types/keter";

interface DashboardLayoutProps {
    sideBarData: SideBarDataType;
    navBarData: any;
    theme: any;
    setTheme: React.Dispatch<React.SetStateAction<any>>;
    children: React.ReactNode;
}

function DashboardLayout(widgetProps: DashboardLayoutProps) {
    const [mini, setMini] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [open, setOpen] = React.useState(true);
    const [currentRoute, setCurrentRoute] = React.useState("SubjectProfile");

    /**
     * Toggles the state of the sidebar between mini and full size.
     */
    function toggleMini() {
        setMini(!mini);
    }
    const sideBarData = widgetProps.sideBarData;

    return (
        <div className="relative flex h-screen w-screen flex-row items-center justify-start gap-2 overflow-hidden dark:bg-background-900">
            {/* Sidebar */}
            <div
                className={`sm:none relative z-50 flex h-full flex-shrink ${
                    mini === false ? "w-[285px]" : "w-[80px]"
                } fixed !z-50 flex min-h-full items-center justify-center transition-all duration-0 ease-linear md:!z-50 lg:!z-50 xl:!z-0
               ${open ? "" : "-translate-x-full"}\`}`}
            >
                <Sidebar
                    sideBarData={sideBarData}
                    mini={mini}
                    open={open}
                    onClose={() => setOpen(false)}
                    hovered={hovered}
                    setHovered={setHovered}
                />
            </div>
            {/* Main Content and Navbar */}
            <div
                className={`absolute mr-2 flex h-full flex-col items-center justify-start pb-3 ${
                    mini === false
                        ? "left-[285px] w-[calc(100vw-285px)]"
                        : "left-[80px] w-[calc(100vw-80px)]"
                }`}
            >
                <div className="flex h-full w-full flex-col items-center justify-start">
                    <Portal>
                        <Navbar
                            firstName={sideBarData.fullName}
                            onOpenSidenav={() => setOpen(!open)}
                            currentPage={currentRoute}
                            theme={widgetProps.theme}
                            setTheme={widgetProps.setTheme}
                            hovered={hovered}
                            mini={mini}
                            setMini={setMini}
                        />
                    </Portal>

                    <Scrollbars
                        autoHide
                        renderTrackVertical={renderTrack}
                        renderThumbVertical={renderThumb}
                        renderView={
                            mini === false ? renderView : renderViewMini
                        }
                    >
                        <div
                            className={`mx-auto h-auto min-h-[calc(100%-75px)] p-2 !pt-[165px] ${
                                mini === false
                                    ? "xl:!pt-[100px]"
                                    : "lg:!pt-[100px]"
                            } w-full md:p-2`}
                        >
                            {widgetProps.children}
                        </div>
                        <Footer />
                    </Scrollbars>
                </div>
            </div>
        </div>
    );
}

export default DashboardLayout;
