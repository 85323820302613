import TextField from "src/components/fields/TextField";
import { TestSubjectProfileDataType } from "../../../../../../types/keter";
import { ChangeEvent } from "react";

interface NewSubjectProps {
    updateSubjectData: (e: ChangeEvent<HTMLInputElement>) => void;
    subjectProfileData: TestSubjectProfileDataType;
}

function Profile(widgetProps: NewSubjectProps) {
    const { updateSubjectData, subjectProfileData } = widgetProps;

    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            {/* Header */}
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Profile
            </h4>
            {/* inputs */}

            <div className="mt-2">
                <TextField
                    extra="mb-4"
                    label="About Me"
                    name="aboutMe"
                    placeholder="Tell something about yourself in 150 characters!"
                    id="textarea"
                    cols={30}
                    rows={5}
                    value={subjectProfileData.aboutMe}
                    onChange={updateSubjectData}
                />
            </div>
        </div>
    );
}

export default Profile;
