import { createContext, useContext, useState } from "react";
import { TestCategory, TestDataType } from "src/types/keter";

const StepperContext = createContext({
    testData: {
        id: "",
        name: "",
        description: "",
        evaluationProcess: "",
        testType: "",
        creationDate: "",
        testTime: "",
        category: TestCategory.PSYCHOMETRIC,
    },
    setTestData: null,
});

export function UseContextProvider(props: { children: JSX.Element }) {
    const { children } = props;
    const [testData, setTestData] = useState<TestDataType>({
        id: "",
        name: "",
        description: "",
        evaluationProcess: "",
        testType: "",
        creationDate: "",
        testTime: "",
        category: TestCategory.PSYCHOMETRIC,
    });

    return (
        <StepperContext.Provider value={{ testData, setTestData }}>
            {children}
        </StepperContext.Provider>
    );
}

export function useStepperContext() {
    const { testData, setTestData } = useContext(StepperContext);

    return { testData, setTestData };
}
