import SearchTableUsers from "../../../../views/admin/subjects/overview/components/SearchTableUsersOverview";
import Card from "../../../../components/card";
import { TestSubjectDataType } from "../../../../types/keter/index";

interface UserOverviewProps {
    subjectsData: TestSubjectDataType[];
}

const UserOverview = (widgetProps: UserOverviewProps) => {
    return (
        <Card extra={"w-full h-full mt-3"}>
            <SearchTableUsers subjectsData={widgetProps.subjectsData} />
        </Card>
    );
};

export default UserOverview;
