import { ChangeEvent, useState } from "react";
import Stepper from "./components/Stepper";
import UserInfo from "./components/steps/UserInfo";
import Address from "./components/steps/Address";
import Profile from "./components/steps/Profile";
import {NewUserData, TestSubjectProfileDataType, UserType} from "../../../../types/keter";
import {UseContextProvider} from "../../tests/addTest/contexts/StepperContext";
import StepperControl from "../../tests/addTest/components/StepperControl";
import Card from "../../../../components/card";

function NewUser() {
    const [currentStep, setCurrentStep] = useState(1);
    const [userData, setUserData] =
        useState<NewUserData>({
            id: "",
            governmentId: "",
            firstName: "",
            lastName: "",
            jobTitle: "",
            creationDate: "",
            email: "",
            address: {
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                zip: "",
                country: "",
            },
            birthDate: "",
            profilePic: "",
            userType: UserType.TEST_SUBJECT,
        });

    const steps = [
        { stepNo: 1, name: "Subject Info" },
        { stepNo: 2, name: "Address" },
        { stepNo: 3, name: "Profile" },
    ];
    const updateUserData = (e: ChangeEvent<HTMLInputElement>) => {
        if (
            e.target.name === "addressLine1" ||
            e.target.name === "addressLine2" ||
            e.target.name === "city" ||
            e.target.name === "state" ||
            e.target.name === "zip" ||
            e.target.name === "country"
        ) {
            setUserData({
                ...userData,
                address: {
                    ...userData.address,
                    [e.target.name]: e.target.value,
                },
            });
        } else {
            setUserData({
                ...userData,
                [e.target.name]: e.target.value,
            });
        }
        const _dataProfile: string = JSON.stringify(userData);
        console.log(`Update Subject Profile Data ${_dataProfile}`);
    };

    const saveUserProfileData = () => {
        const _dataProfile: string = JSON.stringify(userData);
        console.log(`Save Subject Profile Data ${_dataProfile}`);
    };
    const displayStep = (step: {
        stepNo: number;
        name: string;
        highlighted?: boolean;
        selected?: boolean;
        completed?: boolean;
    }) => {
        switch (step.stepNo) {
            case 1:
                return (
                    <UserInfo
                        userData={userData}
                        updateUserData={updateUserData}
                    />
                );
            case 2:
                return (
                    <Address
                        userData={userData}
                        updateSubjectData={updateUserData}
                    />
                );
            case 3:
                return (
                    <Profile
                        userData={userData}
                        updateUserData={updateUserData}
                    />
                );
            default:
        }
    };

    const handleClick = (direction: string) => {
        let newStep = currentStep;

        direction === "next" ? newStep++ : newStep--;
        // check if steps are within bounds
        newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
        if (currentStep === steps.length) {
            saveUserProfileData();
        }
    };
    return (
        <div className="mt-3 h-full w-full">
            <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />
            <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3 3xl:w-7/12">
                <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
                    <Stepper
                        action={setCurrentStep}
                        steps={steps}
                        currentStep={currentStep}
                    />
                </div>
                <Card extra={"h-full mx-auto pb-3"}>
                    <div className="rounded-[20px]">
                        <UseContextProvider>
                            {displayStep(steps[currentStep - 1])}
                        </UseContextProvider>
                    </div>
                    {/* navigation button */}

                    <StepperControl
                        handleClick={handleClick}
                        currentStep={currentStep}
                        steps={steps}
                    />
                </Card>
            </div>
        </div>
    );
}

export default NewUser;
