import React, { useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Default from "../../../layouts/auth/defaultLayout/index";
import PinInput from "react-pin-input";
import { verificationCodeAuth } from "src/api/authClientApi";
import {ValidateVerificationCodeType, AuthVerificationResponseType} from "src/api/types";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function VerificationDefault() {
    const [verificationCode, setVerificationCode] = useState("");
    const [confirmationToken, setConfirmationToken] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const query = useQuery();

    useEffect(() => {
        const token = query.get("confirmation");
        if (token) {
            setConfirmationToken(token);
        } else {
            setError("Token de confirmación no encontrado");
        }
    }, [query]);

    const handleUnlock = async() => {
        setLoading(true);
        if (!confirmationToken) {
            setError("Token de confirmación no encontrado");
            return;
        }

        setError("");

        const validateVerificationCode: ValidateVerificationCodeType = { confirmationToken, verificationCode };

        const response: AuthVerificationResponseType = await verificationCodeAuth(validateVerificationCode);
        if (response.success) {
            console.log("Exito");
            navigate("/");
        } else {
            console.log(error);
            setError(response.message || "Código de verifación es inválido.");
        }
        setLoading(false);
    };

    return (
        <Default
            maincard={
                <div className="mb-16 flex h-full w-full items-center justify-center dark:!bg-navy-900 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
                    {/* Sign in section */}
                    <div className="mt-[16vh] flex flex-col rounded-[20px] bg-white pb-16 pt-12 dark:!bg-navy-900 md:pr-6 lg:pr-0 xl:pr-24">
                        <h2 className="mt-1 text-4xl font-bold text-navy-600 dark:text-white">
                            Verificación de 2 pasos
                        </h2>
                        <p className="ml-1 mt-[15px] text-[15px] text-gray-600">
                            Ingresa tu código de correo electrónico de verificación en dos <br/> pasos para desbloquear
                        </p>
                        {/* verification */}
                        <div className="mb-4 mt-7 flex w-full items-center justify-center">
                            <PinInput
                                length={4}
                                initialValue=""
                                onChange={(value, index) => {}}
                                type="numeric"
                                inputMode="number"
                                style={{ marginBottom: "10px" }}
                                inputStyle={{
                                    borderWidth: "2px",
                                    width: "92px",
                                    height: "92px",
                                    borderColor: "#E0E5F2",
                                    borderRadius: "16px",
                                    color: "#2B3674",
                                    fontSize: "36px",
                                }}
                                inputFocusStyle={{
                                    borderColor: "#4318FF",
                                    borderWidth: "3px",
                                }}
                                onComplete={(value) => setVerificationCode(value)}
                                autoSelect={true}
                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                            />
                        </div>
                        {/* button */}
                        <button
                            onClick={handleUnlock}
                            className="linear h-[50px] w-full rounded-xl bg-brand-500 text-base font-medium text-white transition duration-200 hover:bg-brand-600 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        >
                            {loading ? "Desbloqueando..." : "Desbloquear"}
                        </button>
                        <p className="mt-4 text-sm font-medium text-navy-700 dark:text-gray-500">
                            No lo has recibido?
                            <span className="text-sm font-medium text-brand-500 dark:text-white">
                                {" "}
                                Reenviar un nuevo código{" "}
                            </span>
                        </p>

                        {error && (
                            <p className="mt-4 text-lg text-brand-500 dark:text-white">
                                {error}
                            </p>
                        )}
                    </div>
                </div>
            }
        />
    );
}

export default VerificationDefault;
