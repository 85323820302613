// Auth Imports
import ForgotPasswordDefault from "./views/auth/forgotPassword/ForgotPasswordDefault";
import LockDefault from "./views/auth/lock/LockDefault";
import SignInDefault from "./views/auth/signIn/SignInDefault";
import SignUpDefault from "./views/auth/signUp/SignUpDefault";
import VerificationDefault from "./views/auth/verification/VerificationDefault";

const routes = [
    // --- Authentication ---

    // --- Sign In ---
    {
        name: "Sign In",
        path: "/sign-in",
        component: <SignInDefault />,
    },
    // --- Sign Up ---
    {
        name: "Sign Up",
        path: "/sign-up",

        component: <SignUpDefault />,
    },
    // --- Verification ---
    {
        name: "Verification",
        path: "/verification",

        component: <VerificationDefault />,
    },
    // --- Lock ---
    {
        name: "Lock",
        path: "/lock",

        component: <LockDefault />,
    },
    // --- Forgot Password ---
    {
        name: "Forgot Password",
        path: "/forgot-password",

        component: <ForgotPasswordDefault />,
    },
    {
        name: "Users",
        path: "/subjectsOverviewLayout/users",
    },
];
export default routes;
