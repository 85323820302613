/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
} from "@chakra-ui/accordion";
import { MenuItem } from "../../../types/keter";
import { FaCircle } from "react-icons/fa";
import { activeItem } from "../../../utils";

interface LinksProps {
    menuItems: MenuItem[];
    mini: boolean;
    hovered: boolean;
}

export function SidebarLinks(widgetProps: LinksProps) {
    //  Chakra color mode
    let location = useLocation();

    const { menuItems, mini, hovered } = widgetProps;

    const createLinks = (menuItem: MenuItem) => {
        return menuItem.items.length > 0 ? (
            <Accordion allowToggle key={menuItem.path}>
                <AccordionItem mb="8px" border="none" key={menuItem.path}>
                    <AccordionButton
                        display="flex"
                        _hover={{ bg: "unset" }}
                        _focus={{ boxShadow: "none" }}
                        borderRadius="8px"
                        w={{ sm: "100%", xl: "100%" }}
                        px={"0px"}
                        py="0px"
                        bg={"transparent"}
                        ms={0}
                        mb="4px"
                    >
                        <div
                            className={`mb-1.5 flex w-full flex-col items-center ${
                                mini === false
                                    ? " justify-between pl-2 pr-7"
                                    : " justify-center"
                            }`}
                        >
                            <div
                                className={`align-center flex w-full flex-row items-center ${
                                    mini === false
                                        ? "justify-start"
                                        : "justify-center"
                                }`}
                            >
                                <div
                                    className={`mr-3.5 flex items-center justify-center text-brand-500 dark:text-white ${
                                        mini === false ? "flex" : "hidden"
                                    }`}
                                >
                                    <AccordionIcon
                                        ms="auto"
                                        className={`!text-gray-600 
                          ${mini === false ? "block" : "block xl:hidden"}`}
                                        display={
                                            mini === false
                                                ? "block"
                                                : { base: "block", xl: "none" }
                                        }
                                        transform={
                                            menuItem.icon
                                                ? null
                                                : "translateX(-70%)"
                                        }
                                    />
                                </div>
                                <p
                                    className={`text-700 mr-auto block w-full font-medium text-navy-700 dark:text-white ${
                                        activeItem(
                                            menuItem.name.toLowerCase(),
                                            location
                                        )
                                            ? "text-[24px] text-brand-500 dark:text-white"
                                            : "text-[20px] text-gray-600 dark:text-gray-700"
                                    } ${
                                        mini === false
                                            ? "text-left"
                                            : "text-center"
                                    }`}
                                >
                                    {mini === false
                                        ? menuItem.name
                                        : menuItem.name[0]}
                                </p>
                            </div>
                        </div>
                    </AccordionButton>
                    <AccordionPanel
                        pe={menuItem.icon ? null : "0px"}
                        py="0px"
                        ps={mini === false ? "8px" : "base:8px xl:0px"}
                        display={
                            mini === false ? "block" : "base:block xl:flex"
                        }
                    >
                        {createAccordionLinks(menuItem.items)}
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        ) : (
            <Accordion allowToggle key={menuItem.path}>
                <AccordionItem mb="8px" border="none" key={menuItem.path}>
                    <AccordionButton
                        display="flex"
                        _hover={{ bg: "unset" }}
                        _focus={{ boxShadow: "none" }}
                        borderRadius="8px"
                        w={{ sm: "100%", xl: "100%" }}
                        px={"0px"}
                        py="0px"
                        bg={"transparent"}
                        ms={0}
                        mb="4px"
                    >
                        <div
                            className={`mb-1.5 flex w-full flex-col items-center ${
                                mini === false
                                    ? " justify-between pl-2 pr-7"
                                    : " justify-center"
                            }`}
                        >
                            <div
                                className={`align-center flex w-full flex-row items-center ${
                                    mini === false
                                        ? "justify-start"
                                        : "justify-center"
                                }`}
                            >
                                <div
                                    className={`mr-3.5 flex items-center justify-center text-brand-500 dark:text-white ${
                                        mini === false ? "flex" : "hidden"
                                    }`}
                                >
                                    <AccordionIcon
                                        ms="auto"
                                        className={`!text-gray-600 
                          ${mini === false ? "block" : "block xl:hidden"}`}
                                        display={
                                            mini === false
                                                ? "block"
                                                : { base: "block", xl: "none" }
                                        }
                                        transform={
                                            menuItem.icon
                                                ? null
                                                : "translateX(-70%)"
                                        }
                                    />
                                </div>
                                <p
                                    className={`text-700 mr-auto block w-full font-medium text-navy-700 dark:text-white ${
                                        activeItem(
                                            menuItem.name.toLowerCase(),
                                            location
                                        )
                                            ? "text-[24px] text-brand-500 dark:text-white"
                                            : "text-[20px] text-gray-600 dark:text-gray-700"
                                    } ${
                                        mini === false
                                            ? "text-left"
                                            : "text-center"
                                    }`}
                                >
                                    {mini === false
                                        ? menuItem.name
                                        : menuItem.name[0]}
                                </p>
                            </div>
                        </div>
                    </AccordionButton>
                </AccordionItem>
            </Accordion>
        );
    };

    const createAccordionLinks = (menuItems: MenuItem[]) => {
        return menuItems.map((menuItem, index) => {
            return menuItem.items.length > 0 ? (
                createLinks(menuItem)
            ) : (
                <Link key={index} to={menuItem.path}>
                    <div
                        className={`relative ${
                            mini === false
                                ? "ml-7"
                                : mini === true && hovered === true
                                ? "ml-7"
                                : "ml-7 xl:ml-4"
                        } mb-1 flex hover:cursor-pointer`}
                    >
                        <li
                            className="my-[3px] flex cursor-pointer items-center px-8"
                            key={index}
                        >
                            <span className={`text-brand-500 dark:text-white`}>
                                <FaCircle className={`mr-0.5 h-1.5 w-1.5`} />
                            </span>
                            <span
                                className={`ml-2 flex text-sm ${
                                    activeItem(menuItem.path, location) === true
                                        ? "font-medium text-navy-700 dark:text-white"
                                        : "font-medium text-gray-600"
                                }`}
                            >
                                {mini === false
                                    ? menuItem.name
                                    : menuItem.name[0]}
                            </span>
                        </li>
                    </div>
                </Link>
            );
        });
    };
    return (
        <>
            {menuItems.map((item, index) => {
                return createLinks(item);
            })}
        </>
    );
}

export default SidebarLinks;
