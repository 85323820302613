import {
    AdminNewBatteryType,
    AdminNewTestType, NewBatteryType, NewTestType
} from "./types";

const base_url = "http://localhost:3001"

async function newTestAdmin (newTestData: NewTestType) {
    try{
        const response = await fetch(`${base_url}/add-test`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newTestData),
        });
        if (!response.ok) {
            throw new Error('Error al crear examen.');
        }
        var data:AdminNewTestType = await response.json();
        return data;
    }
    catch(error){
        console.error(error);
        const response:AdminNewTestType =  {
            message: "Error al crear examen.",
            success: false
        };
        return response
    }
}

async function newBatteryAdmin (newBatteryData: NewBatteryType) {
    try{
        const response = await fetch(`${base_url}/add-test-battery`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newBatteryData),
        });
        if (!response.ok) {
            throw new Error('Error al crear bateria.');
        }
        var data:AdminNewBatteryType = await response.json();
        return data;
    }
    catch(error){
        console.error(error);
        const response:AdminNewBatteryType =  {
            message: "Error al crear bateria.",
            success: false
        };
        return response
    }
}


export { newTestAdmin, newBatteryAdmin};