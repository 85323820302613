import React from "react";

interface SaveTestDataProps {
    saveNewTestData: () => void;
    setCurrentStep: (step: number) => void;
    currentStep: number;
}

const SaveTestData: React.FC<SaveTestDataProps> = () => {
    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            {/* Header */}
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Test Info
            </h4>

            {/* content */}

            <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2"></div>
        </div>
    );
};

export default SaveTestData;
