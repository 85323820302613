import {
    AuthForgotPasswordType, AuthLockResponseType,
    AuthSignInResponseType, AuthSignUpResponseType,
    AuthVerificationResponseType, ForgotPasswordType, LockType,
    UserSignInPayloadType, UserSignUpPayloadType,
    ValidateVerificationCodeType
} from "./types";

const base_url = "http://localhost:3001"

async function signIn (signInPayload: UserSignInPayloadType) {
    try{
        const response = await fetch(`${base_url}/sign-in`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(signInPayload),
        });
        if (!response.ok) {
            throw new Error('Error al iniciar sesión');
        }
        var data:AuthSignInResponseType = await response.json();
        return data;
    }
    catch(error){
        console.error(error);
        const response:AuthSignInResponseType =  {
            message: "Error al iniciar sesión. Por favor, inténtelo de nuevo.",
            success: false
        };
        return response
    }

}

async function signUp (signUpPayload: UserSignUpPayloadType) {
    try{
        const response = await fetch(`${base_url}/sign-up`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(signUpPayload),
        });
        if (!response.ok) {
            throw new Error('Error al crear cuenta.');
        }
        var data:AuthSignUpResponseType = await response.json();
        return data;
    }
    catch(error){
        console.error(error);
        const response:AuthSignUpResponseType =  {
            message: "Error al crear cuenta.",
            success: false
        };
        return response
    }

}

async function verificationCodeAuth (validateVerificationCode: ValidateVerificationCodeType){
    try{
        const response = await fetch(`${base_url}/validate-code`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(validateVerificationCode),
        });
        if (!response.ok) {
            throw new Error('Código de verifación es inválido');
        }
        var data:AuthVerificationResponseType = await response.json();
        return data;
    }
    catch(error){
        console.error(error);
        const response:AuthVerificationResponseType =  {
            message: "Código de verifación es inválido",
            success: false
        };
        return response
    }
}

async function unlockAuth (lock: LockType){
    try{
        const response = await fetch(`${base_url}/unlock-account`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(lock),
        });
        if (!response.ok) {
            throw new Error('Cuenta no desbloqueada');
        }
        var data:AuthLockResponseType = await response.json();
        return data;
    }
    catch(error){
        console.error(error);
        const response:AuthLockResponseType =  {
            message: "Cuenta no desbloqueada",
            success: false
        };
        return response
    }
}

async function forgotPasswordAuth (forgotPassword: ForgotPasswordType){
    try{
        const response = await fetch(`${base_url}/email-confirmation`, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(forgotPassword),
        });
        if (!response.ok) {
            throw new Error('Correo no válido');
        }
        var data:AuthForgotPasswordType = await response.json();
        return data;
    }
    catch(error){
        console.error(error);
        const response:AuthForgotPasswordType =  {
            message: "Correo no válido",
            success: false
        };
        return response
    }
}

export { signIn, signUp, verificationCodeAuth, unlockAuth, forgotPasswordAuth };