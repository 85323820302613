import React, { useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InputField from "../../../components/fields/InputField";
import Default from "../../../layouts/auth/defaultLayout/index";
import {unlockAuth} from "src/api/authClientApi";
import {LockType, AuthLockResponseType} from "src/api/types";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function LockDefault() {
    const [password, setPassword] = useState("");
    const [confirmationToken, setConfirmationToken] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const query = useQuery();

    useEffect(() => {
        const token = query.get("confirmation");
        if (token) {
            setConfirmationToken(token);
        } else {
            setError("Token de confirmación no encontrado");
        }
    }, [query]);

    const handleLock = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        if (!confirmationToken) {
            setError("Token de confirmación no encontrado");
            return;
        }

        setError("");

        const validateLock: LockType = { confirmationToken, password };

        const response: AuthLockResponseType = await unlockAuth(validateLock);
        if (response.success) {
            console.log("Exito");
            navigate("/sign-in");
        } else {
            console.log(error);
            setError(response.message || "Cuenta no desbloqueada");
        }
        setLoading(false);
    };

    return (
        <Default
            maincard={
                <div className="mb-16 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
                    <div className="mt-[16vh] flex w-[500px] flex-col rounded-[20px] pb-16 pt-12 md:pr-6 lg:pr-0 xl:pr-24">
                        {/* Lock section */}
                        <div className="flex flex-col rounded-[20px] bg-white dark:!bg-navy-900">
                            <h2 className="mb-2 text-4xl font-bold text-navy-600 dark:text-white">
                                Esthera Parkson
                            </h2>
                            <p className="mb-8 ml-1 mt-[10px] text-base text-gray-600">
                                Ingresa tu contraseña para desbloquear tu cuenta
                            </p>
                            
                            <form onSubmit={handleLock}>
                                {/* Email */}
                                <InputField
                                    variant="auth"
                                    label="Contraseña"
                                    placeholder="Contraseña de tu cuenta"
                                    id="email"
                                    type="password"
                                    value={password}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                />
                                {/* button */}
                                <button
                                    type="submit"
                                    className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                >
                                    {loading ? "Desbloqueando..." : "Desbloquear"}
                                </button>

                                {error && (
                                    <p className="mt-4 text-lg text-brand-500 dark:text-white">
                                        {error}
                                    </p>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            }
        />
    );
}

export default LockDefault;
