import Card from "../card";
import Switch from "../switch";
import { SettingToggleType } from "../../types/keter";
import React, { useEffect, useState } from "react";

interface SettingsToggleCardProps {
    cardHeader: string;
    settings: SettingToggleType[];
    buttonText?: string;
}

function SettingsToggleCard(widgetProps: SettingsToggleCardProps) {
    let [settings, setSettings] = useState<SettingToggleType[]>([]);
    useEffect(() => {
        setSettings(widgetProps.settings);
        return () => {
            setSettings([]);
        };
    }, []);
    function settingsToggleHandler(e: React.ChangeEvent<HTMLInputElement>) {
        const updatedSettings = settings.map((setting) => {
            if (setting.settingId === e.target.id) {
                return {
                    ...setting,
                    settingValue: e.target.checked,
                };
            }
            return setting;
        });
        setSettings(updatedSettings);
    }
    const saveSettings = () => {
        console.log(`Save Settings ${JSON.stringify(settings)}`);
    };
    return (
        <Card extra={"w-full h-full p-3 mb-3"}>
            <div className="relative mb-3 flex items-center justify-between pt-1">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    {widgetProps.cardHeader}
                </h4>
            </div>
            <div className="flex h-full flex-col justify-between">
                <div className="flex flex-col">
                    {/*settings*/}
                    {settings.map((setting) => {
                        return (
                            <div
                                className="mt-3 flex items-center gap-3"
                                key={setting.settingId}
                            >
                                <Switch
                                    id={setting.settingId}
                                    checked={setting.settingValue}
                                    onChange={(e) => settingsToggleHandler(e)}
                                />
                                <label
                                    htmlFor={setting.settingId}
                                    className="text-base font-medium text-navy-700 dark:text-white"
                                >
                                    {setting.settingLabel}
                                </label>
                            </div>
                        );
                    })}

                    {widgetProps.settings.length === 0 && (
                        <div className="flex h-full w-full items-center justify-center">
                            <p className="text-base text-gray-600">
                                No settings available
                            </p>
                        </div>
                    )}
                </div>
                <div className="flex w-full justify-end">
                    <button
                        onClick={() => saveSettings()}
                        className="linear rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                        {widgetProps.buttonText || "Save Changes"}
                    </button>
                </div>
            </div>
        </Card>
    );
}

export default SettingsToggleCard;
