'use client';
import React from 'react';
import {FiSearch} from "react-icons/fi";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { GoChevronLeft } from "react-icons/go";
import { GoChevronRight } from "react-icons/go";


function OverviewUser() {
    return(
        <div>
            <div className="mt-8 ml-10 xl:mt-10 flex flex-row items-center">
                <div
                    className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[290px]">
                    <p className="pl-3 pr-2 text-xl">
                        <FiSearch className="h-5 w-5 text-navy-600 dark:text-white"/>
                    </p>
                    <input
                        type="text"
                        placeholder="Busca un usuario"
                        className="my-4 block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                    />
                </div>
                <button className="bg-navy-600 text-white font-semibold rounded-full py-[15px] px-[20px] ml-6">
                    Buscar usuario
                </button>
                <div className="ml-auto">
                    <button className="bg-green-400 text-white font-semibold rounded-full py-[15px] px-[50px] mr-8">
                        Crear usuario
                    </button>
                </div>
            </div>
            
            <div className="w-full mt-4">
                <div className="flex text-gray-500 text-sm font-medium py-2 border-b border-gray-300">
                    <div className="w-1/6">USUARIO</div>
                    <div className="w-1/6">BATERIA</div>
                    <div className="w-1/6">PUESTO</div>
                    <div className="w-1/6">STATUS EVALUACION</div>
                    <div className="w-1/6">TIPO DE PRUEBA</div>
                    <div className="w-1/6">USER ACTIONS</div>
                </div>
            </div>
            
            {/*Perfil 1*/}
            
            <div className="flex items-center w-full my-10">
                <div className="w-1/6 flex flex-row">
                    <img src="path_to_image" alt="Profile" className="w-10 h-10 rounded-full mx-4"/>
                    <p className="font-medium text-navy-600 mt-2">Vlad Mihalache</p>
                </div>
                <div className="w-1/6">
                    <p className="text-sm text-navy-600">Solo Cleaver</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">Gerente</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">Por contestar</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">cleaver</p>
                </div>
                <div className="w-1/6">
                    <a href="#edit" className="text-blue-500 underline">Editar usuario</a>
                </div>
            </div>
            
            {/*Perfil 2*/}
            
            <div className="flex items-center w-full my-10">
                <div className="w-1/6 flex flex-row">
                    <img src="path_to_image" alt="Profile" className="w-10 h-10 rounded-full mx-4"/>
                    <p className="font-medium text-navy-600 mt-2">Fredy Andrei</p>
                </div>
                <div className="w-1/6">
                    <p className="text-sm text-navy-600">Solo Cleaver</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">lorem</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">Por contestar</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">cleaver</p>
                </div>
                <div className="w-1/6">
                    <a href="#edit" className="text-blue-500 underline">Editar usuario</a>
                </div>
            </div>
            
            {/*Perfil 3*/}
            
            <div className="flex items-center w-full my-10">
                <div className="w-1/6 flex flex-row">
                    <img src="path_to_image" alt="Profile" className="w-10 h-10 rounded-full mx-4"/>
                    <p className="font-medium text-navy-600 mt-2">Anonymous User</p>
                </div>
                <div className="w-1/6">
                    <p className="text-sm text-navy-600">Solo Cleaver</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">lorem</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">Por contestar</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">cleaver</p>
                </div>
                <div className="w-1/6">
                    <a href="#edit" className="text-blue-500 underline">Editar usuario</a>
                </div>
            </div>
            
            {/*Perfil 4*/}
            
            <div className="flex items-center w-full my-10">
                <div className="w-1/6 flex flex-row">
                    <img src="path_to_image" alt="Profile" className="w-10 h-10 rounded-full mx-4"/>
                    <p className="font-medium text-navy-600 mt-2">Marcus Aurelius</p>
                </div>
                <div className="w-1/6">
                    <p className="text-sm text-navy-600">Solo Cleaver</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">lorem</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">Terminada</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">cleaver</p>
                </div>
                <div className="w-1/6">
                    <a href="#edit" className="text-blue-500 underline">Editar usuario</a>
                </div>
            </div>
            
            {/*Perfil 5*/}
            
            <div className="flex items-center w-full my-10">
                <div className="w-1/6 flex flex-row">
                    <img src="path_to_image" alt="Profile" className="w-10 h-10 rounded-full mx-4"/>
                    <p className="font-medium text-navy-600 mt-2">Lorentz Michael</p>
                </div>
                <div className="w-1/6">
                    <p className="text-sm text-navy-600">Solo Cleaver</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">lorem</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">Terminada</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">cleaver</p>
                </div>
                <div className="w-1/6">
                    <a href="#edit" className="text-blue-500 underline">Editar usuario</a>
                </div>
            </div>
            
            {/*Perfil 6*/}
            
            <div className="flex items-center w-full my-10">
                <div className="w-1/6 flex flex-row">
                    <img src="path_to_image" alt="Profile" className="w-10 h-10 rounded-full mx-4"/>
                    <p className="font-medium mt-2">Tim Wilson</p>
                </div>
                <div className="w-1/6">
                    <p className="text-sm text-navy-600">Solo Cleaver</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">lorem</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">Terminada</p>
                </div>
                <div className="w-1/6">
                    <p className="text-navy-600">cleaver</p>
                </div>
                <div className="w-1/6">
                    <a href="#edit" className="text-blue-500 underline">Editar usuario</a>
                </div>
            </div>
            
            <div className="flex flex-row">
                <div className="flex flex-row">
                    <div className="ml-4 mt-1 mr-3 text-navy-600">
                        Mostrar filas por página
                    </div>
                    
                    <Menu>
                        <div>
                            <MenuButton
                                className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                6
                                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
                            </MenuButton>
                        </div>
                    </Menu>
                </div>
                
                <div className="flex flex-row mt-1 ml-auto mr-20">
                    <div className="ml-4 text-navy-600">
                        1-6 of 32
                    </div>
                    <div className="flex flex-row ml-6">
                        <GoChevronLeft className="h-5 w-5 text-navy-600 dark:text-white"/>
                        <GoChevronRight className="h-5 w-5 text-navy-600 dark:text-white"/>
                    </div>
                </div>
            </div>
        
        
        </div>
    )
}

export default OverviewUser;