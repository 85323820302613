import React from "react";
import notfound from "src/assets/img/notfound/notfound.png";

function NotFound() {
    return (
        <div className="mt-16 flex flex-col justify-center items-center text-center xl:mt-40">
            <div className="flex flex-row">
                <p className="text-[#2b3674] text-8xl font-black xl:text-[130px]">
                    4
                </p>
                <img src={notfound} alt="Not Found" className="w-20 h-auto -mx-3 xl:w-32 xl:-mx-6"/>
                <p className="text-[#2b3674] text-8xl font-black xl:text-[130px]">
                    4
                </p>
            </div>

            <p className="text-[#2b3674] text-2xl font-semibold py-3 xl:py-4 xl:text-3xl">
                Ah, caray. No encontramos la página.
            </p>

            <p className="text-xs xl:text-sm">
                <span className="text-[#707eae]">
                    Quizás sea mejor que empieces de nuevo en nuestra página de inicio...
                </span>
                <a href="#" className="pl-2 text-[#4318ff]">
                    Regresa a inicio aquí.
                </a>
            </p>

        </div>
    );
}

export default NotFound;
