import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "../../../components/fields/InputField";
import Default from "../../../layouts/auth/defaultLayout/index";
import Checkbox from "../../../components/checkbox";
import { signIn } from "src/api/authClientApi";
import {UserSignInPayloadType, AuthSignInResponseType} from "src/api/types";

function SignInDefault() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSignIn = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        setError("");

        const signInPayload: UserSignInPayloadType = { email, password };

        const response:AuthSignInResponseType = await signIn(signInPayload);
        console.log("Respuesta de autenticación:", response.success);
        if (response.success) {
            console.log("Exito");
            navigate(`/verification/?confirmation=${response.confirmationToken}`);
        }
        else {
            console.log(error);
            setError(response.message || "Error al iniciar sesión. Por favor, inténtelo de nuevo.");
        }
        setLoading(false);

    };

    return (
        <Default
            maincard={
                <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
                    {/* Sign in section */}
                    <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                        <h3 className="mb-2.5 text-4xl font-bold text-navy-600 dark:text-white">
                            Iniciar sesión
                        </h3>
                        <p className="mb-6 text-base text-gray-600">
                            Ingresa tu correo electrónico y contraseña para iniciar sesión!
                        </p>

                        <form onSubmit={handleSignIn}>
                            {/* Email */}
                            <InputField
                                variant="auth"
                                extra="mb-3"
                                label="Email*"
                                placeholder="mail@simmmple.com"
                                id="email"
                                type="text"
                                value={email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                            />

                            {/* Password */}
                            <InputField
                                variant="auth"
                                extra="mb-3"
                                label="Contraseña*"
                                placeholder="Min. 8 characters"
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                            />
                            {/* Checkbox */}
                            <div className="mb-4 flex items-center justify-between px-2">
                                <div className="mt-2 flex items-center">
                                    <Checkbox
                                        checked={rememberMe}
                                        onChange={() => setRememberMe(!rememberMe)}
                                    />
                                    <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                                        Mantenerme conectado
                                    </p>
                                </div>
                                <a
                                    className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                                    href="/forgot-password"
                                >
                                    ¿Olvidaste tu contraseña?
                                </a>
                            </div>
                            <button
                                type="submit"
                                className="linear w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                disabled={loading}
                            >
                                {loading ? "Cargando..." : "Ingresar"}
                            </button>

                        </form>

                        {error && (
                            <p className="mt-4 text-lg text-brand-500 dark:text-white">
                            {error}
                            </p>
                        )}
                        
                    </div>
                </div>
            }
        />
    );
}

export default SignInDefault;
