import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "../../../components/fields/InputField";
import Default from "../../../layouts/auth/defaultLayout/index";
import Checkbox from "../../../components/checkbox";
import {signUp} from "src/api/authClientApi";
import {UserSignUpPayloadType, AuthSignUpResponseType} from "src/api/types";

function SignUpDefault() {
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSignUp = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        if (name === '' || lastName === '' || email === '' || password === '') {
            setError("Missing fields.");
            setLoading(false);
            return;
        }
        else if (!agreeTerms) {
            setError("Accept terms.");
            setLoading(false);
            return;
        }

        setError("");

        const signUpPayload: UserSignUpPayloadType = { name, lastName, email, password };

        const response:AuthSignUpResponseType = await signUp(signUpPayload);
        console.log("Respuesta de autenticación:", response.success);
        if (response.success) {
            console.log("Exito");
            navigate(`/lock/?confirmation=${response.confirmationToken}`);
        }
        else {
            console.log(error);
            setError(response.message || "Error al iniciar sesión. Por favor, inténtelo de nuevo.");
        }
        setLoading(false);
    };

    return (
        <Default
            maincard={
                <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
                    {/* Sign up section */}
                    <div className="mt-[10vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
                        <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
                            Sign Up
                        </h3>
                        <p className="my-[20px] ml-1 text-base text-gray-600">
                            Enter your email and password to sign up!
                        </p>

                        <form onSubmit={handleSignUp}>

                            {/* user info */}
                            <div className="mb-3 flex w-full items-center justify-center gap-4">
                                <div className="w-1/2">
                                    <InputField
                                        variant="auth"
                                        extra="mb-3"
                                        label="First Name*"
                                        placeholder="John"
                                        id="firstname"
                                        type="text"
                                        value={name}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                                    />
                                </div>

                                <div className="w-1/2">
                                    <InputField
                                        variant="auth"
                                        extra="mb-3"
                                        label="Last Name*"
                                        placeholder="Doe"
                                        id="lastname"
                                        type="text"
                                        value={lastName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                                    />
                                </div>
                            </div>
                            {/* Email */}
                            <InputField
                                variant="auth"
                                extra="mb-3"
                                label="Email*"
                                placeholder="mail@simmmple.com"
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                            />
                            {/* Password */}
                            <InputField
                                variant="auth"
                                extra="mb-3"
                                label="Password*"
                                placeholder="Min 8 characters"
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                            />
                            {/* Checkbox */}
                            <div className="mt-4 flex items-center justify-between px-2">
                                <div className="flex">
                                    <Checkbox
                                        id="checkbox"
                                        checked={agreeTerms}
                                        onChange={() => setAgreeTerms(!agreeTerms)}
                                    />
                                    <label
                                        htmlFor="checkbox"
                                        className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white"
                                    >
                                        By creating an account means you agree to
                                        the Terms and Conditions, and our Privacy
                                        Policy
                                    </label>
                                </div>
                            </div>

                            {/* button */}

                            <button
                                type="submit"
                                className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                            >
                                {loading ? "Cargando..." : "Ingresar"}
                            </button>

                        </form>


                        <div className="mt-3">
                        <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                            Already a member?
                        </span>
                            <a
                                href="/sign-in"
                                className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                            >
                                Sign In
                            </a>
                        </div>

                        {error && (
                            <p className="mt-4 text-lg text-brand-500 dark:text-white">
                                {error}
                            </p>
                        )}

                    </div>
                </div>
            }
        />
    );
}

export default SignUpDefault;
