import authImg from "../../../assets/img/auth/auth.png";
import LoginImg from "../../../assets/img/auth/FondoLogin.png";
import { Link } from "react-router-dom";
import Footer from "../../../components/footer/FooterAuthDefault";

function Index(props: { maincard: JSX.Element }) {
    const { maincard } = props;
    return (
        <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
                <div className="mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                    <Link to="/admin" className="mt-0 w-max lg:pt-10">
                        <div className="mx-auto flex h-fit w-fit items-center hover:cursor-pointer">
                            <svg
                                width="8"
                                height="12"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.70994 2.11997L2.82994 5.99997L6.70994 9.87997C7.09994 10.27 7.09994 10.9 6.70994 11.29C6.31994 11.68 5.68994 11.68 5.29994 11.29L0.709941 6.69997C0.319941 6.30997 0.319941 5.67997 0.709941 5.28997L5.29994 0.699971C5.68994 0.309971 6.31994 0.309971 6.70994 0.699971C7.08994 1.08997 7.09994 1.72997 6.70994 2.11997V2.11997Z"
                                    fill="#A3AED0"
                                />
                            </svg>
                            <p className="ml-3 text-sm text-gray-600">
                                Volver al panel principal
                            </p>
                        </div>
                    </Link>
                    {maincard}
                    <div className="absolute right-0 hidden h-full min-h-screen lg:block lg:w-[49vw] 2xl:w-[44vw]">
                        <div style={{backgroundImage: `url(${LoginImg})`,}} className={`absolute flex h-full w-full bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]` }>
                            <div className="relative flex h-full flex items-center justify-center w-full flex flex-col">
                                <div className="flex flex-row text-center">
                                    <h1 className="text-white text-5xl xl:text-6xl 2xl:text-7xl font-bold mr-4">
                                        MIND
                                    </h1>
                                    <h1 className="text-white text-5xl xl:text-6xl 2xl:text-7xl">
                                        SCOPE
                                    </h1>
                                </div>
                                <div className="flex flex-row text-center">
                                    <h1 className="text-white text-5xl xl:text-6xl 2xl:text-7xl font-bold mr-4">
                                        BY
                                    </h1>
                                    <h1 className="text-white text-5xl xl:text-6xl 2xl:text-7xl">
                                        CLOUDKETER
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer
                    copyright="2024 CloudKeter"
                    contactMail="contact@cloudketer.com"
                    privacyURL="#"
                    terms="#"
                />
            </div>
        </div>
    );
}

export default Index;
