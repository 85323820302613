import InputField from "src/components/fields/InputField";
import { ChangeEvent } from "react";
import {
    TestBatteryType,
    TestCategory,
    TestDataType,
} from "../../../../../../types/keter";
import TextField from "../../../../../../components/fields/TextField";

interface NewBatteryProps {
    newBatteryData: TestBatteryType;
    updateNewBatteryData: (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void;
}

function BatteryInfo(widgetProps: NewBatteryProps) {
    const testCategories = Object.values(TestCategory);
    const { newBatteryData, updateNewBatteryData } = widgetProps;

    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            {/* Header */}
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Battery Info
            </h4>
            {/* content */}
            <div className="mt-7 grid grid-cols-1 gap-3">
                <InputField
                    extra="mb-3"
                    label="Battery Name"
                    name="name"
                    onChange={updateNewBatteryData}
                    value={newBatteryData.name}
                    placeholder="eg. Cleaver"
                    id="name"
                    type="text"
                />

                <TextField
                    extra="mb-4"
                    label="Description"
                    name="description"
                    placeholder="Tell something about yourself in 150 characters!"
                    id="textarea"
                    cols={30}
                    rows={5}
                    value={newBatteryData.description}
                    onChange={updateNewBatteryData}
                />
            </div>
        </div>
    );
}

export default BatteryInfo;
