import InputField from "src/components/fields/InputField";
import { ChangeEvent } from "react";
import { TestCategory, TestDataType } from "../../../../../../types/keter";

interface NewTestProps {
    newTestData: TestDataType;
    updateNewTestData: (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => void;
}

function TestInfo(widgetProps: NewTestProps) {
    const testCategories = Object.values(TestCategory);
    const { newTestData, updateNewTestData } = widgetProps;

    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            {/* Header */}
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Test Info
            </h4>
            {/* content */}
            <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2">
                <InputField
                    extra="mb-3"
                    label="Test Name"
                    name="name"
                    onChange={updateNewTestData}
                    value={newTestData.name}
                    placeholder="eg. Cleaver"
                    id="name"
                    type="text"
                />

                <select
                    className="form-select mb-3 mt-7 grid grid-cols-1 gap-3 rounded-[20px] md:grid-cols-2"
                    id="testCategory"
                    onChange={updateNewTestData}
                    value={newTestData.category}
                    name="category"
                >
                    <option value="">Select a category</option>
                    {testCategories.map((category) => {
                        return (
                            <option key={category} value={category}>
                                {category}
                            </option>
                        );
                    })}
                    {/* Add more options as needed */}
                </select>

                <InputField
                    extra="mb-3"
                    label="Evaluation process"
                    placeholder="eg. This test is for ..."
                    id="EvaluationProcess"
                    type="text"
                    onChange={updateNewTestData}
                    value={newTestData.evaluationProcess}
                    name="evaluationProcess"
                />
                <InputField
                    extra="mb-3"
                    label="Test time Limit (Hrs)"
                    placeholder="eg. 2 Hrs"
                    id="TestTime"
                    type="text"
                    onChange={updateNewTestData}
                    value={newTestData.testTime}
                    name="testTime"
                />
            </div>
        </div>
    );
}

export default TestInfo;
