import TextField from "src/components/fields/TextField";
import {NewUserData, TestSubjectProfileDataType} from "../../../../../../types/keter";
import { ChangeEvent } from "react";

interface NewSubjectProps {
    updateUserData: (e: ChangeEvent<HTMLInputElement>) => void;
    userData: NewUserData;
}

function Profile(widgetProps: NewSubjectProps) {
    const { updateUserData, userData } = widgetProps;

    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            {/* Header */}
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                Profile
            </h4>
            {/* inputs */}


        </div>
    );
}

export default Profile;
