import InputField from "src/components/fields/InputField";
import {NewUserData, TestSubjectProfileDataType} from "../../../../../../types/keter";
import { ChangeEvent } from "react";

interface NewUserProps {
    updateUserData: (e: ChangeEvent<HTMLInputElement>) => void;
    userData: NewUserData;
}

function UserInfo(widgetProps: NewUserProps) {
    const { updateUserData, userData } = widgetProps;
    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            {/* Header */}
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                User Info
            </h4>

            {/* content */}

            <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2">
                <InputField
                    extra="mb-3"
                    name="firstName"
                    label="Frist Name"
                    placeholder="John"
                    id="firstname"
                    type="text"
                    value={userData.firstName}
                    onChange={updateUserData}
                />
                <InputField
                    extra="mb-3"
                    label="Last Name"
                    name="lastName"
                    placeholder="Doe"
                    id="lastname"
                    type="text"
                    value={userData.lastName}
                    onChange={updateUserData}
                />

                <InputField
                    extra="mb-3"
                    label="Government ID"
                    name="governmentId"
                    placeholder="1234567890"
                    id="govId"
                    type="text"
                    value={userData.governmentId}
                    onChange={updateUserData}
                />

                <InputField
                    extra="mb-3"
                    label="Email Address"
                    placeholder="mail@simmmple.com"
                    id="email"
                    type="text"
                    value={userData.email}
                    onChange={updateUserData}
                />
                <InputField
                    extra="mb-3"
                    label="Confirm Email Address"
                    placeholder="mail@simmmple.com"
                    id="email"
                    type="text"
                    value={userData.email}
                    onChange={updateUserData}
                />
                <InputField
                    extra="mb-3"
                    label="Date of Birth"
                    name="birthDate"
                    placeholder="01/01/2000"
                    id="birthDate"
                    type="text"
                    value={userData.birthDate}
                    onChange={updateUserData}
                />
                <InputField
                    extra="mb-3"
                    label="Job"
                    name="jobTitle"
                    placeholder="Web Developer"
                    id="job"
                    type="text"
                    value={userData.jobTitle}
                    onChange={updateUserData}
                />
            </div>
        </div>
    );
}

export default UserInfo;
