import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import BatteryInfo from "./components/steps/BatteryInfo";
import Card from "src/components/card";
import { TestBatteryType } from "src/types/keter";
import SaveBatteryData from "./components/steps/SaveBatteryData";
import {newBatteryAdmin} from "src/api/testsClientApi";
import {NewBatteryType, AdminNewBatteryType} from "src/api/types";

function AddTestBattery() {
    const [currentStep, setCurrentStep] = useState(1);
    const [newBatteryData, setNewBatteryData] = useState<TestBatteryType>({
        id: "",
        name: "",
        description: "",
        tests: [],
    });
    const [error, setError] = useState("");
    const navigate = useNavigate();


    const updateNewBatteryTypeData = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        setNewBatteryData({
            ...newBatteryData,
            [e.target.name]: e.target.value,
        });

        const _data: string = JSON.stringify(newBatteryData);
        console.log(`Update Battery Data ${_data}`);
    };

    const saveNewBatteryData = async () => {
        setError("");

        const newBattery: NewBatteryType = {
            batteryName: newBatteryData.name,
            description: newBatteryData.description
        };

        const response: AdminNewBatteryType = await newBatteryAdmin(newBattery);
        console.log("Respuesta de creación de batería:", response.success);
        if (response.success) {
            navigate("/admin/tests/overview");
        } else {
            setError(response.message || "Error al crear batería.");
        }
    };

    const steps = [
        { stepNo: 1, name: "Battery Info" },
        { stepNo: 2, name: "Save Battery" },
    ];
    const displayStep = (step: {
        stepNo: number;
        name: string;
        highlighted?: boolean;
        selected?: boolean;
        completed?: boolean;
    }) => {
        switch (step.stepNo) {
            case 1:
                return (
                    <BatteryInfo
                        newBatteryData={newBatteryData}
                        updateNewBatteryData={updateNewBatteryTypeData}
                    />
                );

            case 2:
                return (
                    <SaveBatteryData
                        saveNewBatteryData={saveNewBatteryData}
                        setCurrentStep={setCurrentStep}
                        currentStep={currentStep}
                    />
                );
            default:
        }
    };

    const handleClick = async (direction: string) => {
        if (direction === "next" && currentStep === steps.length) {
            await saveNewBatteryData();
        } else {
            let newStep = currentStep;
            direction === "next" ? newStep++ : newStep--;
            // check if steps are within bounds
            newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
        }
    };

    return (
        <div className="mt-3 h-full w-full">
            <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />
            <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3 3xl:w-7/12">
                <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
                    <Stepper
                        action={setCurrentStep}
                        steps={steps}
                        currentStep={currentStep}
                    />
                </div>

                <Card extra={"h-full mx-auto pb-3"}>
                    <div className="rounded-[20px]">
                        <UseContextProvider>
                            {displayStep(steps[currentStep - 1])}
                        </UseContextProvider>
                    </div>
                    {/* navigation button */}

                    <StepperControl
                        handleClick={handleClick}
                        currentStep={currentStep}
                        steps={steps}
                    />

                    {error && (
                        <p className="mt-4 text-lg text-brand-500 dark:text-white">
                            {error}
                        </p>
                    )}
                </Card>
            </div>
        </div>
    );
}

export default AddTestBattery;
