import React from "react";
import {
    GeneralInformationCardType,
    SettingsToggleCardDataType,
    TestSubjectProfileDataType,
    UserType,
} from "../../../../types/keter";
import { getFullName } from "../../../../utils";

import UploadCard from "src/components/uploadCard";
import GeneralInformationCard from "src/components/generalInformationCard";
import SettingsToggleCard from "src/components/settingsToggleCard";
import BannerCard from "src/components/bannerCard";
import EditProfileCard from "src/components/editProfileCard";

/**
 * @typedef {{
 *   profileData: TestSubjectProfileDataType,
 *   generalInformationData: GeneralInformationCardType,
 *   settingsCardData: SettingsToggleCardDataType,
 *   bannerPic: string
 * }} TestSubjectProfileProps
 */

type TestSubjectProfileProps = {
    profileData: TestSubjectProfileDataType;
    generalInformationData: GeneralInformationCardType;
    settingsCardData: SettingsToggleCardDataType;
    bannerPic: string;
};

/**
 * The TestSubjectProfile component renders the profile of a test subject.
 * It includes a banner card, edit profile card, upload card, general information card, and settings toggle card.
 *
 * @param {TestSubjectProfileProps} widgetProps - The properties that define the content of the profile.
 * @returns {JSX.Element} The rendered profile of the test subject.
 */
function TestSubjectProfile(widgetProps: TestSubjectProfileProps) {
    const { profileData, generalInformationData, settingsCardData } =
        widgetProps;
    const fullName: string = getFullName(
        profileData.firstName,
        profileData.lastName,
        profileData.middleName,
        profileData.lastName2
    );
    const userTypeArray: string[] = Object.values(UserType);
    return (
        <div className="mt-3 grid h-auto w-full grid-cols-1 gap-2 overflow-hidden lg:grid-cols-2">
            <div className="flex w-full flex-col items-center justify-start gap-2">
                <BannerCard
                    fullName={fullName}
                    userTypes={userTypeArray}
                    userType={profileData.userType}
                    bannerPic={widgetProps.bannerPic}
                    profilePic={
                        profileData.profilePic !== undefined
                            ? profileData.profilePic
                            : ""
                    }
                />
                <EditProfileCard profileData={profileData} />
            </div>
            <div className="flex w-full flex-col items-center justify-start gap-2">
                <UploadCard
                    uploadMessage={"Test subject files"}
                    uploadButtonText={"Save File"}
                    uploadText={"Add files for the test subject archive"}
                />
                <GeneralInformationCard
                    informationCards={generalInformationData.informationCards}
                    cardHeader={generalInformationData.cardHeader}
                    cardText={generalInformationData.cardText}
                />
                <SettingsToggleCard
                    cardHeader={settingsCardData.cardHeader}
                    settings={settingsCardData.settings}
                />
            </div>
        </div>
    );
}

export default TestSubjectProfile;
