import InputField from "src/components/fields/InputField";
import { TestSubjectProfileDataType } from "../../../../../../types/keter";
import { ChangeEvent } from "react";

interface NewSubjectProps {
    updateSubjectData: (e: ChangeEvent<HTMLInputElement>) => void;
    subjectProfileData: TestSubjectProfileDataType;
}

function UserInfo(widgetProps: NewSubjectProps) {
    const { updateSubjectData, subjectProfileData } = widgetProps;
    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            {/* Header */}
            <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
                User Info
            </h4>

            {/* content */}

            <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2">
                <InputField
                    extra="mb-3"
                    name="firstName"
                    label="Frist Name"
                    placeholder="John"
                    id="firstname"
                    type="text"
                    value={subjectProfileData.firstName}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Last Name"
                    name="lastName"
                    placeholder="Doe"
                    id="lastname"
                    type="text"
                    value={subjectProfileData.lastName}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Phone Number"
                    name="phoneNumber"
                    placeholder="08012345678"
                    id="phone"
                    type="text"
                    value={subjectProfileData.phoneNumber}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Government ID"
                    name="governmentId"
                    placeholder="1234567890"
                    id="govId"
                    type="text"
                    value={subjectProfileData.governmentId}
                    onChange={updateSubjectData}
                />

                <InputField
                    extra="mb-3"
                    label="Email Address"
                    placeholder="mail@simmmple.com"
                    id="email"
                    type="text"
                    value={subjectProfileData.email}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Confirm Email Address"
                    placeholder="mail@simmmple.com"
                    id="email"
                    type="text"
                    value={subjectProfileData.email}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Date of Birth"
                    name="birthDate"
                    placeholder="01/01/2000"
                    id="birthDate"
                    type="text"
                    value={subjectProfileData.birthDate}
                    onChange={updateSubjectData}
                />
                <InputField
                    extra="mb-3"
                    label="Job"
                    name="jobTitle"
                    placeholder="Web Developer"
                    id="job"
                    type="text"
                    value={subjectProfileData.jobTitle}
                    onChange={updateSubjectData}
                />
            </div>
        </div>
    );
}

export default UserInfo;
