/**
 * Constructs a full name from the given name parts.
 *
 * @param {string} firstName - The first name.
 * @param {string} lastName - The last name.
 * @param {string} [middleName] - The middle name (optional).
 * @param {string} [lastName2] - The second last name (optional).
 * @returns {string} The full name.
 */
function getFullName(
    firstName: string,
    lastName: string,
    middleName?: string,
    lastName2?: string
): string {
    let fullName: string = "";

    if (middleName) {
        fullName = `${firstName} ${middleName} ${lastName}`;
    } else {
        fullName = `${firstName} ${lastName}`;
    }
    if (lastName2) {
        fullName += ` ${lastName2}`;
    }
    return fullName;
}

/**
 * Checks if the current route is active based on the browser's location.
 *
 * @param {string} itemPath - The path to check.
 * @param {any} location - The current location object from the browser.
 * @returns {boolean} True if the itemPath is part of the current location's pathname.
 */
function activeItem(itemPath: string, location: any) {
    return location.pathname.includes(itemPath);
}

export { getFullName, activeItem };
