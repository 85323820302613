import React from "react";
import routes from "../../routes";
import FixedPlugin from "../../components/fixedPlugin/FixedPlugin";

interface AuthLayoutProps {
    path: string;
}

export default function Auth(widgetProps: AuthLayoutProps) {
    const getRoutes = (routes: RoutesType[]): any => {
        return routes.map((prop, key) => {
            console.log(prop.path);
            if (prop.path === widgetProps.path) {
                console.log("path match");
                return <div key={key}>{prop.component}</div>;
            }
            return "";
        });
    };
    document.documentElement.dir = "ltr";
    return (
        <div>
            <div className="relative float-right h-full min-h-screen w-full dark:!bg-navy-900">
                <main className={`mx-auto min-h-screen`}>
                    <FixedPlugin />
                    {getRoutes(routes)}
                </main>
            </div>
        </div>
    );
}
